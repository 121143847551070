define(["require", "exports", "date-fns"], function (require, exports, date_fns_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.Datetime = void 0;
    var Datetime = /** @class */ (function () {
        function Datetime() {
        }
        Datetime.match = function (datetime) {
            return (0, date_fns_1.format)(datetime, "EEEE, dd. LLLL");
        };
        Datetime.matchTime = function (datetime) {
            return (0, date_fns_1.format)(datetime, "HH:mm:ss");
        };
        return Datetime;
    }());
    exports.Datetime = Datetime;
});
