var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
define(["require", "exports", "../../../../../../frontend/src/scripts/shared/Api"], function (require, exports, Api_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.Groups = void 0;
    var Groups = /** @class */ (function () {
        function Groups() {
            var _a;
            this.selector = document.querySelector('#groups');
            this.list = (_a = this.selector) === null || _a === void 0 ? void 0 : _a.querySelector('.list');
            if (this.selector) {
                this.init();
            }
        }
        Groups.prototype.init = function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            this.generateGroupLayout();
                            return [4 /*yield*/, this.load()];
                        case 1:
                            _a.sent();
                            this.createGroups();
                            return [2 /*return*/];
                    }
                });
            });
        };
        Groups.prototype.generateGroupLayout = function () {
            this.groupLayout = this.selector.querySelector('.group');
            this.selectorTeams = this.groupLayout.querySelector('.group-teams');
            this.teamLayout = this.selectorTeams.querySelector('.row');
        };
        Groups.prototype.load = function () {
            return __awaiter(this, void 0, void 0, function () {
                var data;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, Api_1.Api.get('/api/groups')];
                        case 1:
                            data = (_a.sent()).data;
                            this.groups = data;
                            return [2 /*return*/];
                    }
                });
            });
        };
        Groups.prototype.createGroups = function () {
            var _this = this;
            var _a;
            var created = (_a = this.groups) === null || _a === void 0 ? void 0 : _a.map(function (group) { return _this.createGroup(group); });
            this.list.innerHTML = created.join('');
        };
        Groups.prototype.createGroup = function (group) {
            var _this = this;
            var groupLayout = this.groupLayout;
            var groupName = groupLayout.querySelector('.group-name');
            groupName.innerText = group.group_name;
            var teams = group.teams
                .sort(function (a, b) { return b.group_points - a.group_points; })
                .map(function (team) { return _this.createTeam(team); });
            this.selectorTeams.innerHTML = teams.join('');
            return groupLayout.outerHTML;
        };
        Groups.prototype.createTeam = function (team) {
            var teamLayout = this.teamLayout;
            var teamName = teamLayout.querySelector('.team-name');
            var teamPoint = teamLayout.querySelector('.team-point');
            var teamWon = teamLayout.querySelector('.team-won');
            var teamDrawn = teamLayout.querySelector('.team-drawn');
            var teamLost = teamLayout.querySelector('.team-lost');
            var teamGoal = teamLayout.querySelector('.team-goal');
            var teamAgainst = teamLayout.querySelector('.team-against');
            teamName.innerText = team.name;
            teamPoint.innerText = String(team.group_points);
            teamWon.innerText = String(team.group_won);
            teamDrawn.innerText = String(team.group_drawn);
            teamLost.innerText = String(team.group_lost);
            teamGoal.innerText = String(team.group_goal);
            teamAgainst.innerText = String(team.group_against);
            return teamLayout.outerHTML;
        };
        return Groups;
    }());
    exports.Groups = Groups;
});
