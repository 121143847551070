define(["require", "exports"], function (require, exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.Storage = void 0;
    var Storage;
    (function (Storage) {
        Storage["Matches"] = "matches";
        Storage["MatchesUpdated"] = "matches_updated";
    })(Storage || (exports.Storage = Storage = {}));
});
