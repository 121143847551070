define(["require", "exports", "./predictions/predictions", "./scores/scores"], function (require, exports, predictions_1, scores_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.Dashboard = void 0;
    var Dashboard = /** @class */ (function () {
        function Dashboard() {
            new scores_1.Scores();
            new predictions_1.Predictions();
        }
        return Dashboard;
    }());
    exports.Dashboard = Dashboard;
});
