var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
define(["require", "exports", "../../../../../../../frontend/src/scripts/shared/Api", "../../../../../../../frontend/src/scripts/shared/services/predictions.service", "../../../../../../../frontend/src/scripts/shared/models/Storage.model", "date-fns", "../../../../../../../frontend/src/scripts/shared/helpers/datetime"], function (require, exports, Api_1, predictions_service_1, Storage_model_1, date_fns_1, datetime_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.Predictions = void 0;
    var MATCH_TYPES;
    (function (MATCH_TYPES) {
        MATCH_TYPES["TODAY"] = "TODAY";
        MATCH_TYPES["NEXT"] = "NEXT";
        MATCH_TYPES["LAST"] = "LAST";
    })(MATCH_TYPES || (MATCH_TYPES = {}));
    var Predictions = /** @class */ (function () {
        function Predictions() {
            var _this = this;
            this.selector = document.getElementById('predictions');
            this.predictions = {};
            this.matches = {};
            this.rankingHistories = {};
            this.matchesType = MATCH_TYPES.TODAY;
            if (this.selector) {
                this.init();
                Array.prototype.forEach.call(document.getElementsByClassName('btn'), function (element) {
                    return element.addEventListener('click', function (e) { return _this.updateShowAllMatch(element); });
                });
            }
        }
        Predictions.prototype.init = function () {
            return __awaiter(this, void 0, void 0, function () {
                var e_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 4, , 5]);
                            return [4 /*yield*/, this.loadMatches()];
                        case 1:
                            _a.sent();
                            return [4 /*yield*/, this.loadRankingHistories()];
                        case 2:
                            _a.sent();
                            return [4 /*yield*/, this.loadPredictions()];
                        case 3:
                            _a.sent();
                            return [3 /*break*/, 5];
                        case 4:
                            e_1 = _a.sent();
                            console.error(e_1);
                            return [3 /*break*/, 5];
                        case 5: return [2 /*return*/];
                    }
                });
            });
        };
        Predictions.prototype.updateShowAllMatch = function (element) {
            this.matchesType = element.getAttribute('data-type');
            element.parentElement.querySelector('.active').classList.remove('active');
            element.classList.add('active');
            this.init();
        };
        Predictions.prototype.loadMatches = function () {
            return __awaiter(this, void 0, void 0, function () {
                var matches, matchesUpdated, shouldUpdate, data, matches_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            matches = JSON.parse(localStorage.getItem(Storage_model_1.Storage.Matches));
                            matchesUpdated = localStorage.getItem(Storage_model_1.Storage.MatchesUpdated);
                            shouldUpdate = (0, date_fns_1.isPast)((0, date_fns_1.addHours)(matchesUpdated, 1));
                            if (!(!(matches === null || matches === void 0 ? void 0 : matches.length) || shouldUpdate)) return [3 /*break*/, 2];
                            return [4 /*yield*/, Api_1.Api.get('/api/matches')];
                        case 1:
                            data = (_a.sent()).data;
                            matches_1 = data;
                            localStorage.setItem(Storage_model_1.Storage.Matches, JSON.stringify(matches_1));
                            localStorage.setItem(Storage_model_1.Storage.MatchesUpdated, (0, date_fns_1.formatISO)(new Date()));
                            _a.label = 2;
                        case 2:
                            if (this.matchesType === MATCH_TYPES.TODAY) {
                                this.matches = matches === null || matches === void 0 ? void 0 : matches.filter(function (m) { return (0, date_fns_1.isEqual)((0, date_fns_1.startOfDay)((0, date_fns_1.parse)(m.start_time, 'yyyy-MM-dd HH:mm:ss', new Date())), (0, date_fns_1.startOfDay)(new Date())); });
                            }
                            else if (this.matchesType === MATCH_TYPES.NEXT) {
                                this.matches = matches === null || matches === void 0 ? void 0 : matches.filter(function (m) {
                                    return (0, date_fns_1.parse)(m.start_time, 'yyyy-MM-dd HH:mm:ss', new Date()) > new Date();
                                });
                            }
                            else if (this.matchesType === MATCH_TYPES.LAST) {
                                this.matches = matches === null || matches === void 0 ? void 0 : matches.filter(function (m) {
                                    return (0, date_fns_1.parse)(m.start_time, 'yyyy-MM-dd HH:mm:ss', new Date()) <= new Date();
                                }).sort(function (a, b) { return new Date(b.start_time) - new Date(a.start_time); });
                            }
                            return [2 /*return*/];
                    }
                });
            });
        };
        Predictions.prototype.loadRankingHistories = function () {
            return __awaiter(this, void 0, void 0, function () {
                var response;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, Api_1.Api.get('/api/ranking-histories')];
                        case 1:
                            response = _a.sent();
                            this.rankingHistories = response === null || response === void 0 ? void 0 : response.data.reduce(function (map, history) { map[history.encounter_id] = history; return map; }, {});
                            return [2 /*return*/];
                    }
                });
            });
        };
        Predictions.prototype.loadPredictions = function () {
            return __awaiter(this, void 0, void 0, function () {
                var predictions, mapMatches, forms;
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, Api_1.Api.get('/api/predictions')];
                        case 1:
                            predictions = _a.sent();
                            this.predictions = predictions === null || predictions === void 0 ? void 0 : predictions.data.reduce(function (map, prediction) { map[prediction.encounter_id] = prediction; return map; }, {});
                            mapMatches = this.matches.map(function (m) { return _this.template(m); });
                            this.selector.innerHTML = mapMatches.join('');
                            forms = this.selector.querySelectorAll('.prediction-form');
                            forms.forEach(function (form) {
                                return form.addEventListener('change', function (e) { return _this.update(e); });
                            });
                            return [2 /*return*/];
                    }
                });
            });
        };
        Predictions.prototype.update = function (form) {
            return __awaiter(this, void 0, void 0, function () {
                var row, inputs, encounter_id, result_team1, result_team2;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            row = form.currentTarget;
                            inputs = row.querySelectorAll('input');
                            if (inputs[0].value === null || inputs[1].value === null || inputs[2].value === null) {
                                return [2 /*return*/];
                            }
                            encounter_id = parseInt(inputs[0].value);
                            result_team1 = parseInt(inputs[1].value) < 0 ? 0 : parseInt(inputs[1].value);
                            result_team2 = parseInt(inputs[2].value) < 0 ? 0 : parseInt(inputs[2].value);
                            inputs[1].value = "".concat(result_team1);
                            inputs[2].value = "".concat(result_team2);
                            if (!(encounter_id > 0 && result_team1 >= 0 && result_team2 >= 0)) return [3 /*break*/, 2];
                            return [4 /*yield*/, predictions_service_1.PredictionsService.update(encounter_id, { encounter_id: encounter_id, result_team1: result_team1, result_team2: result_team2 }, this.matches)];
                        case 1:
                            _a.sent();
                            _a.label = 2;
                        case 2: return [2 /*return*/];
                    }
                });
            });
        };
        Predictions.prototype.template = function (match) {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
            var prediction;
            if (match.force_lock_time) {
                prediction = "<div class=\"team-1-prediction col\">".concat((_b = (_a = this.predictions[match.id]) === null || _a === void 0 ? void 0 : _a.result_team1) !== null && _b !== void 0 ? _b : '', " : ").concat((_d = (_c = this.predictions[match.id]) === null || _c === void 0 ? void 0 : _c.result_team2) !== null && _d !== void 0 ? _d : '', "</div>\n                    <div class=\"point col\">(").concat((_f = (_e = this.rankingHistories[match.id]) === null || _e === void 0 ? void 0 : _e.score) !== null && _f !== void 0 ? _f : "", ")</div>");
            }
            else {
                prediction = "<div class=\"team-1-prediction col-2 col-lg-1\">".concat(this.templateInput('team1', (_h = (_g = this.predictions[match.id]) === null || _g === void 0 ? void 0 : _g.result_team1) !== null && _h !== void 0 ? _h : ''), "</div>\n                    <div class=\"team-2-prediction col-2 col-lg-1\">").concat(this.templateInput('team2', (_k = (_j = this.predictions[match.id]) === null || _j === void 0 ? void 0 : _j.result_team2) !== null && _k !== void 0 ? _k : ''), "</div>");
            }
            return "<div data-match=\"".concat(match.id, "\" class=\"border-bottom pb-3 mb-3 ").concat(match.force_lock_time ? 'force' : '', "\">\n                <form class=\"prediction-form\">\n                  <input type=\"hidden\" name=\"match\" value=\"").concat(match.id, "\" class=\"match d-none\">\n                  <div class=\"start-time row justify-content-md-center pb-4\">\n                      <div class=\"col-12 col-md-6 col-xl-4 text-muted\">\n                          <div class=\"row\">\n                              <div class=\"start-time col\">\n                                    <span class=\"me-3\">").concat(datetime_1.Datetime.match(match.start_time), "</span>\n                                    <span>").concat(datetime_1.Datetime.matchTime(match.start_time), "</span>\n                                </div>\n                              <div class=\"stadium col text-end\">Ort: ").concat((_l = match.stadium) === null || _l === void 0 ? void 0 : _l.city, "</div>\n                          </div>\n                      </div>\n                  </div>\n                  <div class=\"row gy-2 pb-4 align-items-center\">\n                      <div class=\"team-1 col-4 col-lg-5 fw-bold text-center\">").concat(match.team1.name, "</div>\n                      ").concat(prediction, "\n                      <div class=\"team-2 col-4 col-lg-5 fw-bold text-center\">").concat(match.team2.name, "</div>\n                  </div>\n                  <div class=\"row\">\n                      <div class=\"result col-12 text-center\">").concat((_m = match.result1) !== null && _m !== void 0 ? _m : '', " - ").concat((_o = match.result2) !== null && _o !== void 0 ? _o : '', "</div>\n                  </div>\n                  <button type=\"submit\" class=\"d-none\"></button>\n                </form>\n            </div>");
        };
        Predictions.prototype.templateInput = function (className, result) {
            return "<input type=\"number\" class=\"".concat(className, " form-control prediction-input fw-bold\" min=\"0\" value=\"").concat(result !== null && result !== void 0 ? result : '', "\">");
        };
        return Predictions;
    }());
    exports.Predictions = Predictions;
});
