define(["require", "exports", "../../../backend/src/resources/views/main/main"], function (require, exports, main_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    try {
        new main_1.Main();
    }
    catch (e) {
        console.error(e);
    }
});
