define(["require", "exports", "./ranking/rankings", "./groups/groups", "./dashboard/dashboard"], function (require, exports, rankings_1, groups_1, dashboard_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.Main = void 0;
    var Main = /** @class */ (function () {
        function Main() {
            new dashboard_1.Dashboard();
            new rankings_1.Rankings();
            new groups_1.Groups();
        }
        return Main;
    }());
    exports.Main = Main;
});
